<template>
    <div class="flexContainer">
    <div class="flexUpload" ref="flexUpload"  @dragover="highlighting" @dragleave="dropLeave" @dragend="dropLeave" @dragover.prevent="" @drop.prevent="handleDrop">

      <div class="flexUploadTitle">
          <img src="../assets/iconUpload.svg"  height="64" alt=""/>
          <h1> {{ $t("upload.anonymizationUpload") }} </h1>
      </div>
    
      <div class="uploadDocument">
      <!-- <p> {{ $t($root.importedFileName) }} </p> -->
        <div class="uploadSection" ref="uploadSection">
            <input type="file" ref="file" style="display: none" @change="changeFile()" :accept="allowedTypeFiles">
            <button class="pad" @click="selectFile()"> {{ $t("upload.fileChose") }} </button>
            <div class="dragDropBox" ref="dragDropBox" >
                <p class="text"> {{$t("downloadview.dragAndDrop")}} </p>
            </div>
        </div>
    </div>
    </div>
    </div>
</template>


<script>
import store from "../store"
import { useCookies } from 'vue3-cookies'
export default {
  data () {
        return {
            fileName: (store.state.arrayOfDocuments != null ) ? store.state.arrayOfDocuments.name : this.$t("upload.noFileChosen"),
            importedFile: {},
            allowedTypeFiles: '.pdf,.doc,.docx,.txt,.rtf,.jpeg,.jpg,.png',  // use lowercase
                // allowedTypeFiles: '.doc,.docx,.pdf,.rtf,.html,.xls,.xlsx,.csv,.txt,.odt,.odm'
        }
      },
      setup() {
        const { cookies } = useCookies();
        return { cookies };
        },
      methods:{
        selectFile () {
            if (this.$store.state.loggedUser) {
                let fileInputElement = this.$refs.file
                fileInputElement.click()
            } else {
                this.$store.commit('pushMsg', {type: 'error', text: this.$t("StoreCommits.notLoggedIn")})
            }
        },
        changeFile () {
            this.importedFile = this.$refs.file.files[0]
            // console.log(this.$refs.file.files[0])
            if (this.checkFileType(this.importedFile.name)) {
                this.$root.importedFileName = this.importedFile.name
                this.$store.commit('setDocuments', this.importedFile)
                // console.log(this.$refs.file.files[0])
                this.$router.push({
                    name: 'FormView',
                    params: {file: this.$refs.file.files[0]}
                })
            } else {
                this.showModal = true
            }
        },
        checkFileType (testingFile) {
            // create an array with suffixes, then use find instead, if find returns "undefined" then return false, otherwise true.
            let result = false
            let fileExt = this.getExtension(testingFile)
            this.allowedTypeFiles.split(',').forEach((element)=>{
                if (element.replace('.', '') == fileExt){
                    result = true
                }
            })
            return result
        },
        getExtension (filename) {
            var parts = filename.split('.')
            return String(parts[parts.length - 1]).toLowerCase()
        },
        highlighting (event){
            setTimeout(() => {
                this.$refs.flexUpload.classList.add('drop_area_highlight');
                this.$refs.dragDropBox.classList.add('drop_area_highlight');
            }, 1)
            
        },
        dropLeave (event){
            const fromElement = event.relatedTarget || event.toElement;
            const isLeavingUploadSection = !fromElement || !this.$refs.flexUpload.contains(fromElement);

            if (isLeavingUploadSection) {
                event.currentTarget.classList.remove('drop_area_highlight');
                this.$refs.dragDropBox.classList.remove("drop_area_highlight");
            }
        }
        ,
        handleDrop(event) {
            //dragleave()
            this.importedFile = event.dataTransfer.files[0]
            if (this.checkFileType(this.importedFile.name)) {
                this.$root.importedFileName = this.importedFile.name
                this.$store.commit('setDocuments', this.importedFile)
                this.$router.push({
                name: 'FormView',
                params: { file: this.importedFile }
                })
            } else {
                this.showModal = true
            }
    }
    }
}
</script>

<style lang="scss" src="@/assets/css/views/HomeView.scss"/>
<style lang="scss" src="@/assets/css/FileUpload.scss"></style>
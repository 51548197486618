<template>
	<!--Why tf doesnt this work ???-->
	<!-- Update: it does work in HomeView.vue -->
	<div>
		<LoadingOverlay :message="message" />
	</div>
	<div class="highlight">
		<h1>{{ $t("Opened") }}</h1>
	</div>
	<!-- <div v-for="(field, index) in $root.anonymizedFields" :key="index" class="formSection">
        <div @click="field.show = !field.show" class="width100">
            <h2>{{ $t(field.label) }}</h2><img v-if="!field.show" src="../assets/plus.svg"><img v-else src="../assets/minus.svg">
        </div>
        <div v-if="field.show" class="formFields">
            <div v-for="(field, index) in field.parameters" :key="index" class="formField">
                <h3 class="formLabel">{{ $t(String(field.label)) }}</h3>
                <input class="formInput" 
                    autocomplete="off"
                    v-model="field.input" 
                    :id="field.id" 
                    :type="field.type" 
                    :description="field.helpPhrase"
                />
            </div>
        </div>
    </div> -->
	<div class="formSection">
		<div class="width100">
			<h2>{{ $t("ResultViewButtons.AnyData") }}</h2>
		</div>
		<div v-if="showRandomData" class="formFields">
			<div class="formField" v-for="(input, index) in randomData" :key="index">
				<div class="inputType">
					<div class="inputSelect" :id="input.key" @click="activateSelect(input.key)">
						<div class="selected-option">
							{{ input.selectedOption.text }}
						</div>
						<font-awesome-icon icon="caret-down" :class="{ 'rotate-icon': input.activeSelect }"
							class="triangle-btn" />

						<div class="options-container" v-if="input.activeSelect === true">
							<div class="option" v-for="option in options" :value="option.value" :key="option.value" @click="
								selectOption(
									option.text,
									option.value,
									input.value,
									input.key,
									input.key2,
									option.type
								)
								">
								{{ option.text }}
							</div>
						</div>
					</div>
				</div>
				<input @input="validate(input.value, input.selectedOption.value, input.key, input.key2)" class="formInput" autocomplete="off" v-show="datePickerHide(input.selectedOption.type)"
					v-model="input.value" :id="input.key2" :type="input.selectedOption.type"
					:description="$t('ResultViewButtons.AnyDataWrite')" />

				<!-- <VueDatePicker :key="input.key3" v-show="datePickerShow(input.selectedOption.type)"  v-model="date" :format="formatDate"></VueDatePicker> -->

				<img class="addRemoveFormButtons" @click="removeFormField(index)" v-if="index !== 0" :src="minusSvg" />
				<img class="addRemoveFormButtons" @click="addFormField()" v-if="index === 0" :src="plusSvg"  />
			</div>
		</div>
	</div>
	<div class="formButtons">
		<div>
			<button @click="sendDataIntoAnnotation(false)" class="confirmButton">
				{{ $t("ResultViewButtons.ConfirmButton") }}
			</button>
		</div>
		<div>
			<button @click="sendDataIntoAnnotation(true)" class="secondary_btn">
				{{ $t("ResultViewButtons.manualAnotation") }}
			</button>
		</div>
	</div>
</template>

<script>
import { useCookies } from "vue3-cookies";
import EnvProvider from "jvjr-docker-env";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import validator from "../components/validator/validator";
import { ref } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
	name: "FormView",
	components: { LoadingOverlay, VueDatePicker },
	setup() {
		const { cookies } = useCookies();

		const date = ref(new Date());

		const formatDate = (date) => {
			const day = date.getDate();
			const month = date.getMonth() + 1;
			const year = date.getFullYear();

			return `Selected date is ${day}/${month}/${year}`;
		};

		return {
			cookies,
			date,
			formatDate,
		};
	},
	data() {
		return {
			showRandomData: true,

			randomData: [
				{
					key: "key1",
					key2: "key2",
					key3: "key3",
					value: "",
					category: this.$t("formview.AnyInput"),
					hidden_value: "",
					activeSelect: false,
					selectedOption: {
						text: this.$t("formview.AnyInput"),
						value: "Volny Udaj",
						type: "text",
					},
				},
			],
			plusSvg: require("../assets/plus.svg"),
			minusSvg: require("../assets/minus.svg"),
			formData: new FormData(),
			workingStatus: this.$t("StoreCommits.workingStatus"),
			message: this.$t("LoadingMessages.pdfLoading"),

			options: [
				{ value: "Volny Udaj", text: this.$t("formview.AnyInput"), type: "text" },
				{ value: "Datum", text: this.$t("formview.Date"), type: "text" },
				{ value: "Meno", text: this.$t("formview.Name"), type: "text" },
				{ value: "Cislo", text: this.$t("formview.Number"), type: "text" },
				{ value: "Rodne Cislo", text: this.$t("formview.BirthNumber"), type: "text" },
				{ value: "Mail", text: this.$t("formview.Email"), type: "text" },
				{ value: "Telefon", text: this.$t("formview.TelephoneNumber"), type: "text" },
				{ value: "PSC", text: this.$t("formview.PostalCode"), type: "text" },
				{ value: "Cislo Obcianskeho", text: this.$t("formview.IDNumber"), type: "text" },
				{ value: "Cislo Pasu", text: this.$t("formview.PassportNumber"), type: "text" },
				{ value: "Cislo Karty", text: this.$t("formview.Iban"), type: "text" },
				// { value: "Datum-2", text: "Dátum-2", type: "date-custom" },
			],
		};
	},
	methods: {
		datePickerShow(value) {
			if (value === "date-custom") {
				return true;
			}
			return false;
		},
		datePickerHide(value) {
			if (value === "date-custom") {
				return false;
			}
			return true;
		},
		activateSelect(key) {
			// console.log(this.randomData)
			// console.log("activateSelect key ", key);
			let field = this.randomData.find((x) => x.key === key);
			window.addEventListener('keydown', function(event) {
				if(event.key === 'Escape') {
					field.activeSelect = false;
					event.stopPropagation()
				}
			})
			if (field.activeSelect) {
				// console.log("idem ho zatvorit");
				field.activeSelect = false;
			} else {
				// console.log("otvaram");
				field.activeSelect = true;
			}
			// console.log("activateSelect field", field);
		},
		selectOption(text, option, value, key, key2, type) {

			// najdem objekt v randomData podla kluca a nastavim jeho selectedOption
			let field = this.randomData.find((x) => x.key === key);
			field.category = text
			field.selectedOption.value = option;
			field.selectedOption.text = text;
			field.selectedOption.type = type;
			// console.log("option value key key2: ", option, value, key, key2);
			this.validate(value, option, key, key2);
		},

		generateRandomString(length) {
			const characters =
				"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
			let result = "";

			for (let i = 0; i < length; i++) {
				const randomIndex = Math.floor(Math.random() * characters.length);
				result += characters.charAt(randomIndex);
			}

			return result;
		},
		validate(value, category, key, key2) {
			if (category === undefined) {
				category = "Volny Udaj";
			}
			// console.log("validate", value, category);
			if (value !== "") {
				// console.log(Date.parse(value));
				if (validator(value, category)) {
					// console.log("valid");
					document.getElementById(key2).style.border = "1px solid green";
					document.getElementById(key2).style.boxShadow =
						"0 0 0 0.2rem rgba(0, 255, 0, 0.25)";
					this.randomData.find((x) => x.key === key).hidden_value =
						Date.parse(value);
					// console.log(this.randomData);
				} else {
					// console.log("invalid");
					document.getElementById(key2).style.border = "1px solid red";
					document.getElementById(key2).style.boxShadow =
						"0 0 0 0.2rem rgba(255, 0, 0, 0.25)";
				}
			} else {
				document.getElementById(key2).style.border = "1px solid #ced4da";
				document.getElementById(key2).style.boxShadow = "none";
			}
		},
		addFormField() {
			this.randomData.push({
				key: this.generateRandomString(16),
				key2: this.generateRandomString(16),
				key3 : this.generateRandomString(16),
				value: "",
				category: "Ľubovoľný Údaj",
				hidden_value: "",
				activeSelect: false,
				selectedOption: { text: this.$t("formview.AnyInput"), value: "Volny Udaj", type: "text" },
			});
		},
		removeFormField(index) {
			this.randomData.splice(index, 1);
		},
		sendDataIntoAnnotation(manualAnnotation) {
			if (manualAnnotation === true) {
				this.manualAnnotation = true;
				this.message = this.$t("LoadingMessages.pdfLoading");
			} else if (manualAnnotation === false) {
				this.manualAnnotation = false;
				this.message = this.$t("LoadingMessages.downloadLoading");
			}
			const allAttributes = this.prepareDataIntoAnnotationNewFormView();
			var newAllAttributes = []
			// console.log("allAttributes", allAttributes);
			// remove empty attributes
			for (let i = 0; i < allAttributes.length; i++) {
				// console.log(allAttributes[i].content[0].value)
				if (allAttributes[i].content[0].value !== '') {
					newAllAttributes.push(allAttributes[i]);
				}
			}
			// console.log("newAllAttributes", newAllAttributes);
			this.confirmAnnotation(newAllAttributes, manualAnnotation);
		},
		confirmAnnotation(allAttributes, manualAnnotation) {
			this.$store.state.showOverlay = true;
			// TODO: show msg on loader
			this.$store.commit("pushMsg", {
				type: "ok",
				text: this.$t("StoreCommits.sentForProcessing"),
			});
			let file = this.$store.state.arrayOfDocuments;
			this.formData.append("file", file);
			this.formData.append("formJSON", JSON.stringify(allAttributes));
			this.formData.append("manual", manualAnnotation);

			this.axios
				.post(
					EnvProvider.value("API_URL") + this.$root.API_POST,
					this.formData,
					{
						headers: {
							"Content-Type": "multipart/form-data",
							uToken: this.cookies.get("userToken"),
						},
					}
				)
				.then((response) => {
					this.$store.state.showOverlay = false;
					// get the id of document(s) to handle/show
					if (response.status === 200) {
						if (manualAnnotation === true) {
							this.$store.commit("pushMsg", {
								type: "ok",
								text: this.$t("StoreCommits.manualAnnot"),
							});
						} else {
							this.$store.commit("pushMsg", {
								type: "ok",
								text: this.$t("StoreCommits.successfulAnnot"),
							});
						}
						this.confirmSentForm(response.data, manualAnnotation);
					} else {
						this.$store.commit("pushMsg", {
							type: "error",
							text: this.$t("StoreCommits.processingError"),
						});
					}
				})
				.catch((error) => {
					console.log(error);
					this.workingStatus = this.$t("StoreCommits.processingFailed");
					alert(this.workingStatus);
				});
		},
		confirmSentForm(responseData, manualAnnotation = false) {
			if (manualAnnotation) {
				let minexData = JSON.stringify(responseData.minex);
				this.$router.push({
					name: "ManualAnnotationView",
					params: {
						documentUrl: responseData.url,
						fileId: responseData.fileid,
						minex: minexData,
					},
				});
			} else {
				this.$router.push({
					name: "DownloadView",
				});
			}
		},
		prepareDataIntoAnnotation() {
			var allAttributes = [];
			var anonymizedFields = this.$root.anonymizedFields;
			for (var section in anonymizedFields) {
				var sectionData = {
					categoryName: this.$t(anonymizedFields[section].label),
					content: [],
				};
				const params = anonymizedFields[section].parameters;
				for (var field in params) {
					if (params[field].input !== "") {
						var singleParameter = {
							inputName: this.$t(params[field].label),
							value: params[field].input,
						};
						sectionData.content.push(singleParameter);
					}
				}
				if (sectionData.content.length !== 0) {
					allAttributes.push(sectionData);
				}
			}
			if (this.randomData || this.randomData !== "") {
				sectionData = {
					categoryName: this.$t("StoreCommits.anyParam"),
					content: [],
				};
				singleParameter = {
					inputName: "randomData",
					value: this.randomData,
				};
				sectionData.content.push(singleParameter);
				allAttributes.push(sectionData);
			}
			return allAttributes;
		},
		prepareDataIntoAnnotationNewFormView() {
			var allAttributes = [];
			if ( (this.randomData.length > 1) || (this.randomData[0].value !== "") ) {
				for (var form in this.randomData) {
					var sectionData = {
						categoryName: "non-categoric",
						content: [],
					};
					var singleParameter = {
						inputName: this.randomData[form].category,
						value: this.randomData[form].value,
					};
					sectionData.content.push(singleParameter);
					allAttributes.push(sectionData);
				}
			}
			return allAttributes;
		},
	},
	created() {
		this.$store.state.showOverlay = false;
		setTimeout(() => {
			var documentLink = "";
			if (this.$route.query.documentLink) {
				documentLink = decodeURIComponent(this.$route.query.documentLink);
				this.axios
					.get(
						EnvProvider.value("API_URL") +
						this.$root.API_CRZ +
						"?documentLink=" +
						documentLink,
						{
							headers: {
								"Content-Type": "application/octet-stream",
							},
							responseType: "blob",
						}
					)
					.then((response) => {
						if (response.status === 200) {
							var filename = documentLink.split("/").pop();
							var octetStreamMime = "application/octet-stream";
							var contentType =
								response.headers["content-type"] || octetStreamMime;
							var file = new File([response.data], filename, {
								type: contentType,
							});
							this.$store.commit("setDocuments", file);
						} else {
							this.$store.commit("pushMsg", {
								type: "error",
								text: this.$t("StoreCommits.downloadError"),
							});
						}
					});
			}
		}, 200);
	},
};
</script>

<style lang="scss" src="@/assets/css/views/FormView.scss" />
<style lang="scss" src="@/assets/css/SecondaryButton.scss" />
<style lang="scss" src="@/assets/css/ConfirmButton.scss" />

<template>
  <div class="allMenu">
    <div class="menuItem" @click="prelinkToMinex()">
      
      {{ $t("menu.home") }}
    </div>
    <div v-if="($route.name == 'DownloadView' && $store.state.arrayOfDocuments) || $route.name == 'FormView'" @click="changeRouting('FormView')" class="menuItem">
      <div class="menuItemSpace">
        >
      </div>
      {{ $t("menu.enterAnonymizationInfo") }}
    </div>
    <div v-if="$route.name == 'DownloadView'" @click="changeRouting('DownloadView')" class="menuItem">
      <div class="menuItemSpace">
        >
      </div>
       {{ $t("menu.downloadDocuments") }}
    </div>
  </div>
</template>

<script>
import EnvProvider from 'jvjr-docker-env';

export default {
  name: "Menu",
  methods:{
    changeRouting (viewName) {
      console.log(viewName)
      this.$root.clearValues()
      this.$router.push({
        name: viewName,
      })
    },
    prelinkToMinex() {
      window.location.href = EnvProvider.value('HOME_URL') + '/selectProduct'
    },
  }
}
</script>


<style lang="scss" src="@/assets/css/Menu.scss"></style>
import { isValidPhone } from "phone-validation";

function removeAccentsAndSpecialCharacters(str) {
	const replacements = {
		'Š': 'S', 'š': 's', 'Ð': 'Dj', 'Ž': 'Z', 'ž': 'z', 'À': 'A', 'Á': 'A', 'Â': 'A', 'Ã': 'A', 'Ä': 'A',
		'Å': 'A', 'Æ': 'A', 'Ç': 'C', 'È': 'E', 'É': 'E', 'Ê': 'E', 'Ë': 'E', 'Ì': 'I', 'Í': 'I', 'Î': 'I',
		'Ï': 'I', 'Ñ': 'N', 'Ń': 'N', 'Ò': 'O', 'Ó': 'O', 'Ô': 'O', 'Õ': 'O', 'Ö': 'O', 'Ø': 'O', 'Ù': 'U', 'Ú': 'U',
		'Û': 'U', 'Ü': 'U', 'Ý': 'Y', 'Þ': 'B', 'ß': 'Ss', 'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a',
		'å': 'a', 'æ': 'a', 'ç': 'c', 'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e', 'ì': 'i', 'í': 'i', 'î': 'i',
		'ï': 'i', 'ð': 'o', 'ñ': 'n', 'ń': 'n', 'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o', 'ö': 'o', 'ø': 'o', 'ù': 'u',
		'ú': 'u', 'û': 'u', 'ü': 'u', 'ý': 'y', 'ý': 'y', 'þ': 'b', 'ÿ': 'y', 'ƒ': 'f',
		'ă': 'a', 'î': 'i', 'â': 'a', 'ș': 's', 'ț': 't', 'Ă': 'A', 'Î': 'I', 'Â': 'A', 'Ș': 'S', 'Ț': 'T',
	};

	return str.replace(/[^\x00-\x7F]/g, function (char) {
		return typeof replacements[char] !== 'undefined' ? replacements[char] : char;
	});
}

export default function validator(input, type) {
	if (type === "Datum") {
		input = removeAccentsAndSpecialCharacters(input);
		try {
			let date = new Date(input);
			if (date instanceof Date && !isNaN(date)) {
				return true;
			} else {
				return false;
			}
		} catch (err) {
			return false;
		}
	}
	if (type === "Meno") {
		return /^([ \u00c0-\u01ffa-zA-Z'-.])+$/.test(input);
	}

	if (type === "Volny Udaj") {
		return true;
	}

	if (type === "Cislo") {
		if (!isNaN(parseFloat(input)) && isFinite(input)) {
			return true;
		}
		return false;
	}

	if (type === "Rodne Cislo") {
		if (/^\d{6}\/?\d{3,4}$/.test(input)) {
			return true;
		}
		return false;
	}

	if (type === "Cislo Obcianskeho") {
		if (/^[A-Za-z]{2}\d{6}$/.test(input)) {
			return true;
		}
		return false;
	}

	if (type === "Cislo Pasu") {
		if (/^[A-Za-z]{2}\d{6}$/.test(input)) {
			return true;
		}
		return false;
	}

	if (type === "IBAN") {
		let IBANValidator = require("iban-validator-js");
		let result = IBANValidator.isValid(input);
		if (result) {
			return true;
		}
		return false;
	}

	if (type === "Cislo Karty") {
		if (/^(?:\d{2,6} ?){2,7}$/.test(input)) {
			return true;
		}
		return false;
	}

	if (type === "Mail") {
		var validator = require("email-validator");
		if (validator.validate(input)) {
			return true;
		}
		return false;
	}

	if (type === "Telefon") {
		let result = isValidPhone(input);
		if (result === true) {
			return true;
		}
		return false;
	}

	if (type === "PSC") {
		if (/^\d{3}\s?\d{2}$/.test(input)) {
			return true;
		}
		return false;
	}

}

import { createWebHistory, createRouter } from "vue-router";
import HomeView from '../views/HomeView.vue'
import FormView from "../views/FormView.vue"
import DownloadView from "../views/DownloadView.vue"
import ManualAnnotationView from "../views/ManualAnnotationView.vue"
import LoginView from "../views/LoginView.vue"
import EnvProvider from 'jvjr-docker-env';
import axios from 'axios';

const routes = [
{
  path: '/homeview',
  name: 'HomeView',
  component: HomeView,
  meta:{requiresAuth:true},
},
{
  path: '/',
  name: 'LoginView',
  component: LoginView,
  meta:{requiresAuth:false},
},
{
  path: "/formview",
  name: "FormView",
  component: FormView,
  meta:{requiresAuth:true},
},
{
  path: "/downloadview",
  name: "DownloadView",
  component: DownloadView,
  meta:{requiresAuth:true},
},
{
  path: "/manualannotationview",
  name: "ManualAnnotationView",
  component: ManualAnnotationView,
  meta:{requiresAuth:false},
  props: true
},
{
  path: "/:pathMatch(.*)*",
  component: HomeView,
  meta:{requiresAuth:false},
},
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

async function auth(){
  console.log('Startin auth')
  try {
    const response = await axios.get(EnvProvider.value('API_URL') + '/auth')
    if (response.status === 200){
        console.log('true')
        return true
    } else {
        console.log('false')
        return false
    }
  } catch(err){
    console.log(err)
    return false
  }
}

// Authentication logic on routes
router.beforeEach(async (to, from, next) => {
  if (to.name === 'LoginView') {
    next() // login route is always  okay (we could use the requires auth flag below). prevent a redirect loop
  } else if (to.meta && to.meta.requiresAuth === false) {
    next() // requires auth is explicitly set to false
  } else if (await auth()) {
    next() // i'm logged in. carry on
  } else {
    next({ name: 'LoginView' }) // always put your redirect as the default case
  }
})
export default router
